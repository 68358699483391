<template>
  <v-container>
    <p class="hint mt-4">
      При появлении вопросов напишите нам на почту &laquo;<span class="subtitle"
        >ams.support@mozlab.ru</span
      >&raquo;
    </p>
  </v-container>
</template>

<script>
import { onMounted } from "@vue/composition-api";

export default {
  setup() {
    onMounted(() => localStorage.setItem("lastTab", "Help"));
  },
};
</script>
